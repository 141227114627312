<template>
    <div class="documentForm_page">
        <new-header :config="headerConfig"></new-header>

        <div class="form-title">
            <van-icon class="icon" :name="formIcon1" />
            个人基本信息
        </div>
        <div class="form-list">
            <van-form input-align="right" error-message-align="right" ref="Form2">
                <van-field v-model="form2.username" name="name" label="姓名" placeholder="请填写姓名" />
                <van-field readonly v-model="form2.sex" name="sex" label="性别" placeholder="请选择性别"
                    @click="showPickerChange('sex')" />
                <van-field v-model="form2.nation" name="nation" label="民族" placeholder="请填写民族" />
                <van-field readonly v-model="form2.politics" name="politics" label="政治面貌" placeholder="请选择政治面貌"
                    @click="showPickerChange('politics')" />
                <van-field v-model="form2.identity" name="identity" label="身份证号" placeholder="请填写身份证号" />
                <van-field readonly v-model="form2.education" name="education" label="文化程度" placeholder="请选择文化程度"
                    @click="showPickerChange('education')" />
                <van-field v-model="form2.address" name="address" label="当前居住地" placeholder="请填写当前居住地" rows="1" autosize
                    type="textarea" />
                <van-field readonly v-model="form2.phone" name="phone" label="联系方式" placeholder="请填写联系方式"
                    @click="showDialogChange(1)" />
            </van-form>
        </div>

        <div class="form-title">
            <van-icon class="icon" :name="formIcon2" />
            个人一寸照片
        </div>
        <div class="form-image">
            <van-uploader class="uplod_back" :max-count="1" accept="image/*" v-model="imgData.value" upload-text="点击上传"
                :before-read="beforeRead" :after-read="e => afterRead(e, 'imgData')" />
            <div class="iamge_tips">*一寸照片</div>
        </div>

        <div class="form-btn">
            <van-button class="btn" round color="#0091FF" type="primary" @click="submit">生成</van-button>
        </div>

        <!-- 选择器 -->
        <van-popup v-model="showPicker" position="bottom">
            <van-picker show-toolbar :columns="pickerList" @confirm="onConfirm" @cancel="showPicker = false" />
        </van-popup>

        <!-- 手机号换绑提示 -->
        <new-dialog :config="dialogConfig" @confirm="dialogConfirmChange"></new-dialog>

        <!-- 手机号获取验证码 -->
        <van-action-sheet v-model="showPhone" title="更换手机号">
            <div class="page_form">
                <div class="form_item">
                    <input class="value_input" v-model="form.account" placeholder="请输入手机号" />
                </div>
                <div class="form_item">
                    <input class="value_input" v-model="form.code" placeholder="请输入验证码" />
                    <div class="item_btn" @click="getCaptcha">
                        <van-count-down class="count" v-if="codeTime" :time="codeTime" format="ss 秒"
                            @finish="codeTime = null" />
                        <span v-else>获取验证码</span>
                    </div>
                </div>
                <div class="page_btn">
                    <van-button type="primary" block color="#2B2828" round class="btn"
                        @click="codeSubmit">确定</van-button>
                </div>
            </div>
        </van-action-sheet>

    </div>
</template>
<script>
import { getImgToken, uploadImg } from "@/api/common"
import { getUserData_api } from '@/api/perdata'
import Cookies from "js-cookie";

import captcha from '@/utils/captcha';
import valid from '@/utils/valid';
import * as apiCommon from "@/api/common";

import formIcon1 from '@/assets/personalIcon/formIcon1.png'
import formIcon2 from '@/assets/personalIcon/formIcon2.png'
import newHeader from "@/components/newHeader";
import newDialog from '@/components/newDialog.vue';

export default {
    components: {
        newDialog,
        newHeader
    },
    data() {
        return {
            formIcon1: '',
            formIcon2: '',

            headerConfig: {
                show: true,
                title: '电子证件生成',
            },

            union_info: {},
            form2: {},
            rulesErr: {
                username: '姓名未填写',
                sex: '性别未选择',
                nation: '民族未填写',
                politics: '政治面貌未选择',
                identity: '身份证未填写',
                education: '文化程度未选择',
                address: '当前居住地未填写',
                phone: '联系方式未填写',
                userImg: '个人一寸照片未上传',
            },

            dialogConfig: {
                showDialog: false,
                text: '确定更换当前已绑定手机号吗？',
                textAlign: 'center',
                cancelText: '考虑考虑',
                confirmText: '确认',
            },

            showPhone: false,
            form: { account: '', code: '' },
            codeTime: null,
            captcha: null,

            showPicker: false,
            pickType: '',
            pickerList: [],
            sex: ['男', '女'],
            politics: ["群众", "团员", "党员"],
            education: ["初中", "高中", "专科", "本科", "研究生", '其他'],

            imgData: {  //图片上传
                value: [],
                cdUrl: "https://cdn.health.healthplatform.xyz/",
                token: null,
                config: null,
            },
        }
    },
    created() {
        document.title = "电子证件生成";
        this.formIcon1 = formIcon1
        this.formIcon2 = formIcon2

        this.getUploadToken()
        this.getUser()
    },
    methods: {
        //获取用户信息
        async getUser() {
            let res = await getUserData_api()
            if (res.code == 0) {
                let union_info = res.data.union_info ? res.data.union_info : {}

                // 获取入会时间
                this.union_info = union_info
                this.union_info.union_auth_at = res.data.union_auth_at

                this.form2 = {
                    username: union_info.username,
                    sex: union_info.sex,
                    nation: union_info.nation,
                    politics: union_info.politics,
                    identity: union_info.identity,
                    education: union_info.education,
                    address: union_info.address,
                    phone: union_info.phone,
                }
                this.imgData.value = union_info.userImg ? [{ url: union_info.userImg, isImage: true }] : []
            }
        },
        //选择器事件
        showPickerChange(type) {
            this.showPicker = true
            this.pickType = type
            this.pickerList = this[type]
        },
        onConfirm(val) {
            this.form2[this.pickType] = val
            this.showPicker = false
        },

        // 手机号更换提示弹窗
        showDialogChange() {
            this.dialogConfig.showDialog = true
        },
        dialogConfirmChange() {
            this.dialogConfig.showDialog = false
            this.showPhone = true
        },

        // 提交数据生成证件照
        submit() {
            // 非空验证
            let message = ''
            this.form2.userImg = this.imgData.value[0].url
            Object.keys(this.rulesErr).forEach(item => {
                if (!this.form2[item]) message = this.rulesErr[item]
            })
            if (Object.keys(this.form2).length == 0) return this.$toast('请填写信息')
            if (message) return this.$toast(message)

            let obj = JSON.parse(JSON.stringify(this.form2))
            // 判断手机号和身份证是否修改
            if (this.union_info.phone != this.form2.phone) {
                obj.phone = this.form2.phone
                obj.code = this.form2.code
            } else {
                obj.phone = this.union_info.user_phone
            }
            if (this.union_info.identity != this.form2.identity) {
                obj.identity = this.form2.identity
            } else {
                obj.identity = this.union_info.user_identity
            }
            obj.birthday = this.getBirthdayByIdNO(obj.identity)
            obj.union_auth_at = this.union_info.union_auth_at

            let data = JSON.stringify(obj)
            Cookies.set("userData", data)
            setTimeout(() => {
                this.$router.push("../document/index")
            }, 500);
        },
        //身份证获取出生年月日
        getBirthdayByIdNO(IdNO) {
            let birthday = "";
            if (IdNO.length == 18) {
                birthday = IdNO.substr(6, 8);
                return birthday.replace(/(.{4})(.{2})/, "$1-$2-");
            } else if (IdNO.length == 15) {
                birthday = "19" + IdNO.substr(6, 6);
                return birthday.replace(/(.{4})(.{2})/, "$1-$2-");
            } else {
                return "";
            }
        },

        // 获取滑动验证码
        async getCaptchaInfo() {
            let res = await captcha.getCaptcha();
            if (res) {
                this.captcha = res;
                this.timeter()
            }
        },
        // 获取短信验证码
        async timeter() {
            let param = {}, captcha = this.captcha;

            param.mobile = this.form.account;
            param.randstr = captcha.randstr;
            param.ticket = captcha.ticket;

            let res = await apiCommon.getSmsCode(param);
            this.captcha = null;
            this.codeTime = 60 * 1000
            if (!res || typeof res === 'string' || res.error) {
                this.$toast.fail(res ? res.error || res : '获取验证码失败!')
                this.codeTime = null
            }
        },
        getCaptcha() {
            if (!this.form.account) return this.$toast('请先填写手机号');
            if (!valid.isMobile(this.form.account)) return this.$toast('请输入正确的手机号');
            this.getCaptchaInfo();
        },
        codeSubmit() {
            let { account, code } = this.form
            if (account && code) {
                this.showPhone = false
                this.form2.phone = account
                this.form2.code = code
            } else {
                this.$toast.fail('请填写完整信息')
            }
        },

        //获取七牛云上传token
        async getUploadToken() {
            let res = await getImgToken()
            if (!res || typeof res === "string" || res.error) return this.$toast.fail(res ? res.error || res : "获取七牛云信息失败!");
            this.imgData.token = res.data;
            this.imgData.config = "{ useCdnDomain: true }"
        },
        //上传
        async Upload(type, file) {
            let param = new FormData();
            param.append("token", this[type].token);
            param.append("config", this[type].config);
            param.append("file", file);

            let res = await uploadImg(param);
            let imgUrl = this[type].cdUrl + res.key;
            this[type].value = [{ url: imgUrl, isImage: true }]
        },
        // 文件读取前触发
        beforeRead(e) {
            if (e.size > 10 * 1024 * 1024) {
                this.$toast.fail('文件大小不能超过10M')
                return false
            }
            return true
        },
        //文件读取后触发
        afterRead(e, type) {
            this.Upload(type, e.file)
        },
    }
}
</script>
<style scoped>
.documentForm_page {
    box-sizing: border-box;
    background-color: #F9F9FB;
    min-height: 100vh;
    position: relative;
    width: 100%;
}

.icon {
    font-size: 32px;
    vertical-align: -5px;
}

.form-list {
    padding: 10px;
    box-sizing: border-box;
    background-color: #FFFFFF;
}

.form-title {
    font-size: 28px;
    font-weight: bold;
    color: #1A1A1A;
    line-height: 80px;
    margin-left: 40px;
}

.page_form {
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
}

.form_item {
    border: 1px solid #DCDEE0;
    border-radius: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    height: 90px;
}

.value_input {
    width: 100%;
    height: 60px;
    border: none;
    background-color: inherit;
    font-size: 32px;
}

.item_btn {
    width: 200px;
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px solid #DCDEE0;
    font-size: 28px;
    text-align: center;
    color: #0169FF;
}

.count {
    font-size: 28px;
}

.page_btn {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.page_btn>.btn {
    height: 75px;
}

.form-image {
    background-color: #FFFFFF;
    box-sizing: border-box;
    padding: 40px 40px;
}

.form-btn {
    width: 100%;
    padding: 10px 30px;
    box-sizing: border-box;
}

.form-btn>.btn {
    width: 100%;
    height: 88px;
    font-size: 32px;
}

.uplod_back>>>.van-uploader__upload {
    width: 180px;
    height: 180px;
    border-radius: 10px;
}

.uplod_back>>>.van-uploader__preview {
    width: 180px;
    height: 180px;
}

.uplod_back>>>.van-uploader__preview-image {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: 1px solid #ccdbf7;
    box-sizing: border-box;
}

.iamge_tips {
    width: 180px;
    text-align: center;
    line-height: 40px;
    font-size: 28px;
    color: #FF0D0D;
}
</style>